import $ from 'jquery';
import 'jquery-ui';
import './includes/modernizr';
import './includes/jquery.transform2d.js';

/**
*	Jean de Lessard
*/
$(document).ready(function()
{
  // francais
  var page = '';
  if ($("html").attr("lang") == "fr-CA"){
    var this_body_fr = $("body");
    if (this_body_fr.hasClass( "home" ))
    /**/page = 'accueil';
    else if (this_body_fr.hasClass( "design-interieur-commercial" ))
    /**/page = 'espace_comm';
    else if (this_body_fr.hasClass( "bars" ))
    /**/page = 'bar';
    else if (this_body_fr.hasClass( "restaurants-cafes" ))
    /**/page = 'restaurant';
    else if (this_body_fr.hasClass( "commerces" ))
    /**/page = 'commerce';
    else if (this_body_fr.hasClass( "entreprises-services" ))
    /**/page = 'services_soins';
    else if (this_body_fr.hasClass( "bureaux-entreprises" ))
    /**/page = 'espace_bureau';
    else if (this_body_fr.hasClass( "open-space" ))
    /**/page = 'open_space';
    else if (this_body_fr.hasClass( "professionnels" ))
    /**/page = 'professionnels';
    else if (this_body_fr.hasClass( "realisations" ))
    /**/page = 'realisations';
    else if (this_body_fr.hasClass( "vision" ))
    /**/page = 'vision';
    else if (this_body_fr.hasClass( "contact" ))
    /**/page = 'page_contact';
    else if (this_body_fr.hasClass( "a-propos" ))
    /**/page = 'propos';
    else
    /**/page = $('body').data('page');

  } else if ($("html").attr("lang") == "en-CA") {
    var this_body_en = $("body");
    if (this_body_en.hasClass( "home" ))
    page = 'homepage';
    else if (this_body_en.hasClass( "commercial-interior-design" ))
    page = 'comm_space';
    else if (this_body_en.hasClass( "bars" ))
    page = 'bars';
    else if (this_body_en.hasClass( "restaurants-cafes" ))
    page = 'restaurants';
    else if (this_body_en.hasClass( "retail-stores" ))
    page = 'retail';
    else if (this_body_en.hasClass( "service-care-companies" ))
    page = 'service_care';
    else if (this_body_en.hasClass( "workplaces" ))
    page = 'workplace_office';
    else if (this_body_en.hasClass( "open-space" ))
    page = 'open_space';
    else if (this_body_en.hasClass( "professional-offices" ))
    page = 'professionals';
    else if (this_body_en.hasClass( "projects" ))
    page = 'projects';
    else if (this_body_en.hasClass( "vision" ))
    page = 'vision';
    else if (this_body_en.hasClass( "contact" ))
    page = 'contact_page';
    else if (this_body_en.hasClass( "about" ))
    /**/page = 'about';
    else
    /**/page = $('body').data('page');
  }

  /*
  *	Footer at the bottom
  */
  window.onload = function(){
    if ($(window).height() > $("body,html").height()){
      $("footer#footer").css({
        position: "absolute",
        bottom: "0px",
        width: "100%"
      });
    }
  };

  /*
  *	Vertically align text in home templates
  */
  $.fn.Template = function()
  {
    var self = this;

    /*
    *	Browser resize callback
    */
    self.align = function()
    {
      if (screen.width > 640){
        var height = $("div.centered", self).outerHeight(true),
        space = $(self).height();
        if ((space - height) > 0){
          $("div.centered", self).css({
            position: "relative",
            top: ((space - height) / 2) + "px",
            opacity: 1
          });
        }
      } else {
        $("div.centered", self).css({
          position: "relative",
          top: "0px",
          opacity: 1
        });
      }
    };

    self.reorder = function()
    {
      if (screen.width > 640){
        $("a", self).append($("div.text", self));
        $("div.centered", self).prepend($("div.arrow", self));
      } else {
        $("a", self).prepend($("div.text", self));
        $("div.centered", self).append($("div.arrow", self));
      }
    };

    self.hover = function()
    {
      if (Modernizr.csstransforms){
        $("img", self).css({
          transform: "scale(1.05)"
        });
      }
    };

    self.unhover = function()
    {
      if (Modernizr.csstransforms){
        $("img", self).css({
          transform: "scale(1.0)"
        });
      }
    };

    /*
    *	Initialization
    */
    self.init = function()
    {
      if ($(self).hasClass("text-left") || $(self).hasClass("text-left-50") || $(self).hasClass("text-right") || $(self).hasClass("text-right-50")){
        self.align();
        $(window).on("resize", self.align);
        $(window).on("load", self.align);
      }
      if ($(self).hasClass("text-bottom")){
        self.reorder();
        $(window).on("resize", self.reorder);
        $(window).on("load", self.reorder);
      }
      if (Modernizr.csstransforms && $(self).hasClass("project")){
        $(self).on("mouseover", self.hover);
        $(self).on("mouseout", self.unhover);
      }
    }();

    return self;
  };

  $("div.template").each(function(){
    $(this).Template();
  });

  /* Open or close burger menu */
  $('.nav__burger').on('click', function(e) {
    e.preventDefault();
    $('body').addClass('nav-opened');
  });
  $('.nav__close').on('click', function(e) {
    e.preventDefault();
    $('body').removeClass('nav-opened');
  });
  $('.nav').on('click', function(e) {
    if ($(e.target).is($('.nav'))) {
      e.preventDefault();
      $('body').removeClass('nav-opened');
    }
  });
});
